import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/white_logo.webp';
import {
  BrowserRouter as Router
} from "react-router-dom";

export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }
  return (
    <>
      <Navbar expand="lg" className="nav_top_fade"
        style={{
                                      //scroll   //not scroll
          backgroundColor: scrolled ? "#1b1b1b" : "rgba(0, 0, 0, 0.5)",
        }}
      >
        <Container className="">
          <Navbar.Brand href="/">
            <img src={logo} alt="logo" style={{width:"150px"}}/>
            <sup className="text-white p-1 m-1 rounded" style={{border:"1px solid white",fontSize:"10px",marginTop:"100px"}}>Beta</sup>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')} style={{marginTop:"2px"}}>Home</Nav.Link>
              <Nav.Link href="/about-us" className={activeLink === 'aboutus' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('aboutus')} style={{marginTop:"2px"}}>About Us</Nav.Link>
              <Nav.Link href="/#faq" className={activeLink === 'faq' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('faq')} style={{marginTop:"2px"}}>FAQ</Nav.Link>
              <Nav.Link href="/#footer" className={activeLink === 'contactus' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('contactus')} style={{marginTop:"2px"}}>Contact Us</Nav.Link>
        
                  <a href="/#pricing" className={' navbar-link'} onClick={() => onUpdateActiveLink('pricing')} style={{marginTop:"2px"}}>
                    <button className="pricings" style={{backgroundColor:"#fed530",color:"black", border:"none", borderRadius:"20px", padding:"5px 30px", fontWeight:"bold"}}> Pricing </button>
                  </a> 
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}
