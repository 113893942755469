import React, { Component } from "react";
import { NavBar } from "./NavBar";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import MRow from "./MRow";
import "./PageBanner.css";
import PageBannerComp from "./PageBannerComp";
import { ProgressB } from "./ProgressB";
import Footer from "./Footer";
import disnip from "../assets/img/disnip.png";
import tnm from "../assets/img/Banner/tnm.webp"
import stnm from "../assets/img/Banner/stnm.webp";
import waka from "../assets/img/Banner/wakanda.webp";
import swaka from "../assets/img/Banner/swakanda.webp";
import ae from "../assets/img/Banner/AE.webp";


function Hotstar() {
  // generate random number between 1 and 4
  const seq = Math.floor(Math.random() * 3) + 1;

  return (
    <div>
      <NavBar />
      <section className="pbanner" id="home">
        {seq === 1 && (
          <PageBannerComp
           // if window size is less than 991px then image will be large else small
            image={
              window.innerWidth > 700 ? tnm : stnm
            }
            title="The Night Manager"
            description="Follows Jonathan, an ex-soldier, who must infiltrate an arms dealer's inner circle to avenge the death of his girlfriend."
            BannerCompLink="#buy"
            logoLink={disnip}
          />
        )}
        {seq === 2 && (
          <PageBannerComp
          image={
            window.innerWidth > 800 ? waka : swaka
          }
            title="Black Panther: Wakanda Forever"
            description="The people of Wakanda fight to protect their home from intervening world powers as they mourn the death of King T'Challa."
            BannerCompLink="#buy"
            logoLink={disnip}
          />
        )}
        {seq === 3 && (
          <PageBannerComp
          image={
            window.innerWidth > 991 ? ae : ae
          }
            title="Abbott Elementary Season-2"
            description="Follows a group of teachers brought together in one of the worst public schools in the country, simply because they love teaching."
            BannerCompLink="#buy"
            logoLink={disnip}
          />
        )}
        
      </section>
      <div
        className=""
        style={{
          marginTop: "20px",
          marginLeft: "20px",
        }}
      >
        <MRow
          title="New Releases"
          movies={[
            {
              id: 10,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYjU2NDY0ZTktYTM2Zi00NzhlLWIzZGUtMWE0N2MzNGI2YTkxXkEyXkFqcGdeQXVyMTA3MTI2ODc5._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 11,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYjUyNjcyNWUtMDQ1OC00NzAwLTljZjgtZDljMjMwYTAyNjg3XkEyXkFqcGdeQXVyMTMwNDQ0Mzgx._V1_.jpg",
            },
            {
              id: 12,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BOWYzYjU3ZTYtNDNhYy00NThjLWFlYzItOWQzZmNiMDI1OTNhXkEyXkFqcGdeQXVyMjkxNzQ1NDI@._V1_.jpg",
            },
            {
              id: 1,
              imglink:
                "https://englishtribuneimages.blob.core.windows.net/gallary-content/2023/1/2023_1$largeimg_2129900083.jpg",
            },
            {
              id: 2,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BY2VmNzRlODQtMmJhZC00YjJjLTgzOTItY2ZiZDQwMTIyMWEyXkEyXkFqcGdeQXVyMjYwMjMwMzk@._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 3,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BY2FlN2U2NzMtOWE2Ni00MWIyLTk3YzQtM2RjNDFkNTlhYTZmXkEyXkFqcGdeQXVyMTUzMTg2ODkz._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 4,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BM2UzNjQ0ZDEtMWZmNC00ODY5LWIzNmUtM2Q1ODk2MTFhMWYwXkEyXkFqcGdeQXVyMjkxNzQ1NDI@._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 5,
              imglink:
                "https://111.90.140.124/wp-content/uploads/2023/02/w4Bm67jzZf6ELhjt7HBgKCTcDLc.jpg",
            },
            {
              id: 6,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZDA4MzEzODUtMDE2My00ZjQwLTg1YjUtZGM4ZjAwNzZjMWYxXkEyXkFqcGdeQXVyNjQ2MjQ4MzQ@._V1_.jpg",
            },
            {
              id: 7,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNWJjMzg3MDctMzEyZS00ZGU4LWI0MzgtMDY3NTViZjNjMGU4XkEyXkFqcGdeQXVyMjkxNzQ1NDI@._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 8,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMTI1ODVhYjQtNjZlOS00ODk2LWE3YmEtMGMzNTc4Mzc5YTc2XkEyXkFqcGdeQXVyNjQ2MjQ4MzQ@._V1_.jpg",
            },
            {
              id: 9,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNGI3MWYwYjItNzZhYi00ZWIzLTkyMzYtN2JmNjg3ODg1NTg4XkEyXkFqcGdeQXVyMTMwMDA5ODU3._V1_.jpg",
            },
          ]}
          isLargeRow={true}
        />
        <MRow
          title="Popular Movies"
          movies={[
            {
              id: 1,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BY2FlN2U2NzMtOWE2Ni00MWIyLTk3YzQtM2RjNDFkNTlhYTZmXkEyXkFqcGdeQXVyMTUzMTg2ODkz._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 2,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BY2VmNzRlODQtMmJhZC00YjJjLTgzOTItY2ZiZDQwMTIyMWEyXkEyXkFqcGdeQXVyMjYwMjMwMzk@._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 3,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYjg2ZDI2YTYtN2EwYi00YWI5LTgyMWQtMWFkYmE3NmJkOGVhXkEyXkFqcGdeQXVyODE5NzE3OTE@._V1_.jpg",
            },
            {
              id: 4,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMmE2MzYyY2UtYTI2ZS00NjUyLWE5MjEtMmJmMWVmZWFmMTYwXkEyXkFqcGdeQXVyODk2ODI3MTU@._V1_.jpg",
            },
            {
              id: 5,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMjAxZDEyZTUtMTdiMC00M2FkLWFlYWItZTQ3NWQzNzY2NDdjXkEyXkFqcGdeQXVyMTA3MDk2NDg2._V1_.jpg",
            },
            {
              id: 6,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZmY5NjNjYWMtNmJhNi00YTk0LTg0OWUtNDE4OGQ0YmM4NDAwXkEyXkFqcGdeQXVyODE5NzE3OTE@._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 7,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNTliYjlkNDQtMjFlNS00NjgzLWFmMWEtYmM2Mzc2Zjg3ZjEyXkEyXkFqcGdeQXVyMTkxNjUyNQ@@._V1_.jpg",
            },{
              id: 8,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZjAzZjZiMmQtMDZmOC00NjVmLTkyNTItOGI2Mzg4NTBhZTA1XkEyXkFqcGdeQXVyODE5NzE3OTE@._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 9,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYWVhM2U3OTItZWY2OC00YzdlLWE4YTMtMTVlZWZmOTYwMDQwXkEyXkFqcGdeQXVyODk2ODI3MTU@._V1_.jpg",
            },
            {
              id: 10,
              imglink:
                " https://m.media-amazon.com/images/M/MV5BZThmMWFlOWYtY2M0YS00OWIxLWI4MzgtYTJhMDNkZDY4ZjhjXkEyXkFqcGdeQXVyNzc4NzEzNjY@._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 11,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMTc0MTNjN2EtMDFjNS00NWUwLWFhMzctMDg3ZTlmZTk1ZjZmXkEyXkFqcGdeQXVyODk2ODI3MTU@._V1_.jpg",
            },
            {
              id: 12,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BOWYxZDMxYWUtNjNiZC00MDE1LWI2Y2QtNWZhNDAyMGY5ZjVhXkEyXkFqcGdeQXVyODE5NzE3OTE@._V1_FMjpg_UX1000_.jpg",
            },
          ]}
          isLargeRow={true}
        />
        <MRow
          title="Popular Shows"
          movies={[
            {
              id: 1,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYTRiNDQwYzAtMzVlZS00NTI5LWJjYjUtMzkwNTUzMWMxZTllXkEyXkFqcGdeQXVyNDIzMzcwNjc@._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 2,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZjBiOGIyY2YtOTA3OC00YzY1LThkYjktMGRkYTNhNTExY2I2XkEyXkFqcGdeQXVyMTEyMjM2NDc2._V1_.jpg",
            },
            {
              id: 3,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMGZkNjlhMDItOWVmZi00OWQxLWE1ZDgtODFkNWEyYmNjMzQwXkEyXkFqcGdeQXVyNzM4MjU3NzY@._V1_.jpg",
            },
            {
              id: 4,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNTkwOTE1ZDYtODQ3Yy00YTYwLTg0YWQtYmVkNmFjNGZlYmRiXkEyXkFqcGdeQXVyNTc4MjczMTM@._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 5,
              imglink:
                "https://i.pinimg.com/736x/dc/30/8f/dc308fa124933de4961558e5d603e331.jpg",
            },
            {
              id: 6,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BODg1YTE5ZTAtYWJiYS00MGQ0LTk3NDQtNjMyZTA0ZjNmNzBiXkEyXkFqcGdeQXVyNzM4MjU3NzY@._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 7,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNTA2YzMyOWUtODAxNC00ZTI4LThiZWItMzMxYjYxOGFmMDUzXkEyXkFqcGdeQXVyMzE4NjMwMjc@._V1_.jpg",
            },
            {
              id: 8,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYTc5OWNhYjktMThlOS00ODUxLTgwNDQtZjdjYjkyM2IwZTZlXkEyXkFqcGdeQXVyNTA3MTU2MjE@._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 9,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZDgxN2M5M2UtMjkxMC00NWNiLTk1MWEtOGUzNmY5Yjc4Y2RjXkEyXkFqcGdeQXVyMzcyNzQ3MjA@._V1_.jpg",
            },
            {
              id: 10,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMmQ5MzdhNWEtNTU4MS00YzZlLWFiNzUtNDMxMGIxZmNiYmJhXkEyXkFqcGdeQXVyMTI1NDEyNTM5._V1_.jpg",
            },
            {
              id: 11,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNzY1NDE2MWQtNzJlOC00MGZiLTkxN2YtMTQ5YWVkMTA2MGU5XkEyXkFqcGdeQXVyMTE0MDk1NjA1._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 12,
              imglink:
                " https://m.media-amazon.com/images/M/MV5BMzYzOWE0MTItNmYyYS00MDNiLWIyMDAtZDU0NjQ4NmJlNWEzXkEyXkFqcGdeQXVyNjQ2MjQ4MzQ@._V1_FMjpg_UX1000_.jpg",
            },
          ]}
          isLargeRow={true}
        />
      </div>
      <div
        classsName="col-md-11"
        style={{
          marginTop: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <ProgressB
            ott="Hotstar"
            beginnerPrice="5"
            cinephilePrice="8"
            bingePrice="12"
        />        
      </div>
      <section id="buy">
      <a
        href="/#faq"
        className="any_queries"
        style={{
            textDecoration: "none",
            color: "white",
            marginTop: "5px",
            marginBottom: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            fontSize: "10px",
          }}
      >Any queries?</a>
      </section>
      <Footer/>
    </div>
  );
}

export default Hotstar;
