import React, { Component } from "react";
import { NavBar } from "./NavBar";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import MRow from "./MRow";
import "./PageBanner.css";
import PageBannerComp from "./PageBannerComp";
import { ProgressB } from "./ProgressB";
import Footer from "./Footer";
import zee5 from "../assets/img/zee5.png";
import svikram from "../assets/img/Banner/svikram.webp";
import vikram from "../assets/img/Banner/vikram.webp";
import nkb from "../assets/img/Banner/nkb.webp";
import snkb from "../assets/img/Banner/snkb.webp";
import srrr from "../assets/img/Banner/srrr.webp";
import rrr from "../assets/img/Banner/rrr.webp";
function Zee() {
  // generate random number between 1 and 4
  const seq = Math.floor(Math.random() * 3) + 1;

  return (
    <div>
      <NavBar />
      <section className="pbanner" id="home">
        {seq === 1 && (
          <PageBannerComp
          image={
            window.innerWidth > 800 ? vikram : svikram
          }
            title="Vikram"
            description="A high-octane action film where a special investigator is assigned a case of serial Killings, he finds the case is not what it 
            seems to be and leading down this path is only going to end in a war between everyone involved."
            BannerCompLink="#buy"
            logoLink={zee5}
          />
        )}
        {seq === 2 && (
          <PageBannerComp
          image={
            window.innerWidth > 991 ? nkb : snkb
          }
            title="Never Kiss Your Best Friend"
            description="Nakuul Mehta (Sumer), Anya Singh (Tanie), Karan Wahi (Karan) and Sarah Jane Dias (Lavanya) star in S2 of this heart-warming story that redefines the rules of friendship."
            BannerCompLink="#buy"
            logoLink={zee5}
          />
        )}
        {seq === 3 && (
          <PageBannerComp
          image={
            window.innerWidth > 991 ? rrr : srrr
          }
            title="RRR"
            description="A fictitious story about two legendary revolutionaries and their journey away from home before they started fighting for their country in the 1920s."
            BannerCompLink="#buy"
            logoLink={zee5}
          />
        )}
      </section>
      <div
        className=""
        style={{
          marginTop: "20px",
          marginLeft: "20px",
        }}
      >
        <MRow
          title="New Releases"
          movies={[
            {
              id: 1,
              name: "Avengers",
              imglink:"https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.imgur.com%2FFulKzfE.jpg&f=1&nofb=1&ipt=db2d722fd939834f8e26bd4cdb64df54f3254f096c414e2bcb04f6318e6fb71c&ipo=images",
              
            },
            {
              id: 2,
              
              imglink:
                "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.laalsinghchaddhaa.com%2Fwp-content%2Fuploads%2F2022%2F11%2FVedha-Kannada-Movie-Full-Star-Cast-Crew-Wiki.jpg&f=1&nofb=1&ipt=54796b25d57bedd066a9c852631e0799795898471353cb35b136bb7b7452dccd&ipo=images",
              
            },
            {
              id: 3,
              
              imglink:
                "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fsaakshatv.com%2Fwp-content%2Fuploads%2F2023%2F01%2FWhatsApp-Image-2023-01-31-at-4.00.28-PM-750x938.jpeg&f=1&nofb=1&ipt=42708f075719e040e1a20855351e9787028769911f83aba031eb54108cf4afbc&ipo=images",
              
            },
            {
              id: 4,
              
              imglink:
                "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fmedia.ragalahari.com%2Fnews%2Fjan2023%2Fatm-web-series-50million-minutes-jan27.jpg&f=1&nofb=1&ipt=3c0c4993135f0825e42485a5cced675ac01ea81d00ac1ecf2a5b5f9cf1805aa3&ipo=images",
              
            },
            {
              id: 5,
              
              imglink:
                "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.jeded.com%2Fi%2Fchhatriwali.252910.jpg&f=1&nofb=1&ipt=194130f0778c4f158ea99880a033e35d05e39a7514d123641a8f282e71710e4f&ipo=images",
              
            },
            {
              id: 6,
              name: "Avengers",
              imglink:  
                "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fm.media-amazon.com%2Fimages%2FM%2FMV5BY2U1NDMwZGMtY2JhZC00MDJiLWE2NmEtNzM3N2U5NTE5ODllXkEyXkFqcGdeQXVyODA4MDA0Mjg%40._V1_FMjpg_UX1000_.jpg&f=1&nofb=1&ipt=04a5f27403ca614a88bab007e0943f6d9c14aadecaeb124ff4afd42b19fc8330&ipo=images",
              
            },
            {
              id: 7,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYWU4ODIxNWQtYzNiYi00MjRhLTg1MjktNDNhY2E3OWFhYWU0XkEyXkFqcGdeQXVyMTU2MTIyODU2._V1_.jpg",
              
            },
            {
              id: 8,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BY2YzOTkxZTAtZjc1Zi00Njk1LTg5ZTktNDc0MjI3ZWFlZTc3XkEyXkFqcGdeQXVyMTUzOTcyODA5._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 9,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYTg3ZjVkYTQtOGE3NS00NzBjLTg1MWYtY2ZkNzIxY2NhYjhmXkEyXkFqcGdeQXVyMTU1Njg3Nzk3._V1_.jpg",
              
            },
            {
              id: 10,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZmNjNGYxOGEtYjkwMC00YmQyLThjZTYtMThlMmY0MmIzZmRiXkEyXkFqcGdeQXVyMTkzOTcxOTg@._V1_.jpg",
              
            },
            {
              id: 11,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMzU0Y2Q2M2UtZjhlYS00N2YxLWFjYWMtOWJlNDkyY2I2ZTVlXkEyXkFqcGdeQXVyOTI3MzI4MzA@._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 12,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMzBkNjBjYjMtODNjMi00NzA1LWJkYTYtYzg0ODU4ZTE1ZDFiXkEyXkFqcGdeQXVyMTIyNzY0NTMx._V1_FMjpg_UX1000_.jpg",
              
            },
          ]}
          isLargeRow={true}
        />
        <MRow
          title="Popular Movies"
          movies={[
            {
              id: 1,
              name: "Avengers",
              imglink:"https://m.media-amazon.com/images/M/MV5BOGQ3ZjMwZDctZTFkMi00ZjUwLTlmMDUtNDZlYzJjNzZiNTg5XkEyXkFqcGdeQXVyMTMzODk3NDU0._V1_.jpg",
              
            },
            {
              id: 2,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYzJkZDIwYTAtMGU4Mi00NzU3LWI1MWItODg0M2Q1NmIxYmNlXkEyXkFqcGdeQXVyMTIyNzY0NTMx._V1_.jpg",
              
            },
            {
              id: 3,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYjc0MWFiYTMtNmYyOS00ODczLWEyMzItNzI4YjY0YjJjZGVjXkEyXkFqcGdeQXVyNzEwNjg3MjE@._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 4,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMGY5M2MxZjYtMWUwNi00N2RlLTk4NWQtMTY3YjI2ZjAwYjQ5XkEyXkFqcGdeQXVyODk4NTI4NDA@._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 5,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZmM3ZjNiMGMtMTc2NS00OTQ5LWI2MzItY2Q0Yjg1Y2I5MTZlXkEyXkFqcGdeQXVyMTEzNzg0Mjkx._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 6,
              name: "Avengers",
              imglink:  
                "https://m.media-amazon.com/images/M/MV5BMmRiZTQxNmYtZTUwMC00ZDA5LWI0YzYtNDBkYTBjYjczYjAwXkEyXkFqcGdeQXVyMTIyNzY0NTMx._V1_.jpg",
              
            },
            {
              id: 7,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMTU3MTQyNjQwM15BMl5BanBnXkFtZTgwNDgxNDczNTM@._V1_.jpg",
              
            },
            {
              id: 8,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMTU2NTYxODcwMF5BMl5BanBnXkFtZTcwNDk1NDY0Nw@@._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 9,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNmZkOThkNWYtOTY1ZC00MzZiLWExMDEtMTNhZDcyOGZiZTM5XkEyXkFqcGdeQXVyMTA5MTkyMzU2._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 10,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMmY5ZDU5ZTgtOGM2MC00MDM2LTkxY2EtN2E2NzNjNGFkYjU4XkEyXkFqcGdeQXVyMTA4NjE0NjEy._V1_.jpg",
              
            },
            {
              id: 11,
              
              imglink:
                "",
              
            },
            {
              id: 12,
              
              imglink:
                "",
              
            },
          ]}
          isLargeRow={true}
        />
        <MRow
          title="Popular Shows"
          movies={[
            {
              id: 1,
              name: "Avengers",
              imglink:"https://m.media-amazon.com/images/M/MV5BYzMyMDQ3ZWMtZjI3NS00NTEyLTk5Y2EtNDAyMjNjMjI0MmJjXkEyXkFqcGdeQXVyMTExMTIzMTA5._V1_.jpg",
              
            },
            {
              id: 2,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYzI0ZmIzNmItMzVjMy00ZmNjLWFiMjAtMDY2OWQ3YmRiMjQ3XkEyXkFqcGdeQXVyMTIzMzg0MTM2._V1_.jpg",
              
            },
            {
              id: 3,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMmYwMjkzMzQtYWJiZS00ZTMxLTkyYjAtZGEwOWQ4OWNiNzNmXkEyXkFqcGdeQXVyMTIyNzY0NTMx._V1_.jpg",
              
            },
            {
              id: 4,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BOTUzNzFmYTEtODYyMS00ZjAwLTgxMTQtY2E0YjQ3Y2MzNmM2XkEyXkFqcGdeQXVyMTIyNzY0NTMx._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 5,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BOWZlYTRhNjYtMzgyZS00YzhmLThkMjAtNDNiNWJmYmZjNjBmXkEyXkFqcGdeQXVyMTIzMzg0MTM2._V1_UY1200_CR285,0,630,1200_AL_.jpg",
              
            },
            {
              id: 6,
              name: "Avengers",
              imglink:  
                "https://m.media-amazon.com/images/M/MV5BYjkyNmFkODMtMWZkOC00ODkxLTk3ODYtZjk2YzcyNzIwZmQ2XkEyXkFqcGdeQXVyMTIyNzY0NTMx._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 7,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZGI3NTI5YzgtNTNmMy00OTYwLWJlNmItNjc2ZmRiZWJiYTVmXkEyXkFqcGdeQXVyMTIyNzY0NTMx._V1_UY1200_CR165,0,630,1200_AL_.jpg",
              
            },
            {
              id: 8,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BOGEzYmQ0NDYtMGY5Yi00ZjI1LTgzMzUtYmNhOTU0NGZmZWMxXkEyXkFqcGdeQXVyMTIyNzY0NTMx._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 9,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZGJkM2Q0NDMtM2YwYS00MDc1LTlhZDItMDk4Zjg2MzkwNDk2XkEyXkFqcGdeQXVyMTIyNzY0NTMx._V1_.jpg",
              
            },
            {
              id: 10,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNmM0YWMwNDktODVmMy00MmJhLTg4OWMtOTMwNzBjYmQxMjFlXkEyXkFqcGdeQXVyMTIzMzg0MTM2._V1_.jpg",
              
            },
            {
              id: 11,
              
              imglink:
                "",
              
            },
            {
              id: 12,
              
              imglink:
                "",
              
            },
          ]}
          isLargeRow={true}
        />
      </div>
      <div
        classsName="col-md-11"
        style={{
          marginTop: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <ProgressB
            ott="Zee5"
            beginnerPrice="4"
            cinephilePrice="6"
            bingePrice="8"
        />        
      </div>
      <section id="buy">
      <a
        href="/#faq"
        className="any_queries"
        style={{
            textDecoration: "none",
            color: "white",
            marginTop: "5px",
            marginBottom: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            fontSize: "10px",
          }}
      >Any queries?</a>
      </section>
      <Footer/>
    </div>
  );
}

export default Zee;
