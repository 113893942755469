import React, { Component } from "react";
import { NavBar } from "./NavBar";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import MRow from "./MRow";
import "./PageBanner.css";
import PageBannerComp from "./PageBannerComp";
import { ProgressB } from "./ProgressB";
import Footer from "./Footer";
import prime from "../assets/img/prime.webp";
import farzi from "../assets/img/Banner/farzi.webp"
import sfarzi from "../assets/img/Banner/sfarzi.webp"
import ssr from "../assets/img/Banner/ssr.webp"
import sr from "../assets/img/Banner/sr.webp"
import kantara from "../assets/img/Banner/kantara.webp"
import skantara from "../assets/img/Banner/skantara.webp"
import tf from "../assets/img/Banner/tf.webp"
import stf from "../assets/img/Banner/stf.webp"

function Prime() {
  // generate random number between 1 and 4
  const seq = Math.floor(Math.random() * 4) + 1;

  return (
    <div>
      <NavBar />
      <section className="pbanner" id="home">
        {seq === 1 && (
          <PageBannerComp
          image={
            window.innerWidth > 700 ? sr : ssr
          }
            title="Setha Ramam"
            description="An orphan soldier, Lieutenant Ram's life changes, after he gets a letter from a girl named Sita. He meets her and love blossoms between them."
            BannerCompLink="#buy"
            logoLink={prime}
          />
        )}
        {seq === 2 && (
          <PageBannerComp
          image={
            window.innerWidth > 850 ? farzi : sfarzi
          }
            title="Farzi"
            description="An artist who gets pulled into the murky high stakes of a con job and a fiery task force officer on the mission to rid the country of his menaces in a fast-paced,
             edgy one-of-a-kind thriller."
            BannerCompLink="#buy"
            logoLink={prime}
          />
        )}
        {seq === 3 && (
          <PageBannerComp
          image={
            window.innerWidth > 750 ? kantara : skantara
          }
            title="Kantara"
            description="It involves culture of Kambala and Bhootha Kola. A human and nature conflict where Shiva is a rebel who defends his village and nature.
             A death leads to war between villagers and evil forces." 
            BannerCompLink="#buy"
            logoLink={prime}
          />
        )}
        {seq === 4 && (
          <PageBannerComp
          image={
            window.innerWidth > 700 ? tf : stf
          }
            title="The Office"
            description="A mockumentary on a group of typical office workers, where the workday consists of ego clashes, inappropriate behavior, and tedium."
            BannerCompLink="#buy"
            logoLink={prime}
          />
        )}
      </section>
      <div
        className=""
        style={{
          marginTop: "20px",
          marginLeft: "20px",
        }}
      >
        <MRow
          title="New Releases"
          movies={[
            {
              id: 1,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNThlNGZjZDQtYzJjMi00MzM4LWFkNjctNDMwZmFjNWJkM2I4XkEyXkFqcGdeQXVyMTUzNTgzNzM0._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 2,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNGMyNTBlZjMtNjE3NS00NmU2LThmZGItZWMxMWExYWE1ZDQwXkEyXkFqcGdeQXVyMTU2NDkwOTAw._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 3,
              imglink:
                "https://preview.redd.it/ennalum-entaliya-jan-6-2023-release-v0-dd3919o1gp4a1.jpg?auto=webp&s=878446507085fa9eaab71db761555a72beb4eb5a",

            },
            {
              id: 4,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZWYzOGEwNTgtNWU3NS00ZTQ0LWJkODUtMmVhMjIwMjA1ZmQwXkEyXkFqcGdeQXVyMjkwOTAyMDU@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 5,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BN2U3OTJjNzYtMjUwZC00ZmViLTk0MjgtMDNkNzJiYjdmMDQ3XkEyXkFqcGdeQXVyMTA3MDk2NDg2._V1_.jpg",

            },
            {
              id: 6,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYzZkOGUwMzMtMTgyNS00YjFlLTg5NzYtZTE3Y2E5YTA5NWIyXkEyXkFqcGdeQXVyMjkwOTAyMDU@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 7,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYjE2YjY4YjgtNjkyYS00ZDQ0LWE5ZDUtZGU3NDU3NzRlZWRlXkEyXkFqcGdeQXVyMTIxMDk2NDE4._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 8,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZjgwNDA3MmUtMTQ3Yy00ZDFmLTgwMTktNTBlMTQ3ZTI5MjYxXkEyXkFqcGdeQXVyMTM1MTE1NDMx._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 9,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNGQ3YjM3MWMtMmIxNC00YjI0LWJhMmUtZjJiMjA0ZjY4MDNkXkEyXkFqcGdeQXVyMTA3MDk2NDg2._V1_.jpg",

            },
            {
              id: 10,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZDdiMmQ1ZTMtMDQ0NS00NDE1LTgzYzktZDdkYmU0ZjgzZmM3XkEyXkFqcGdeQXVyMjAwNzczNTU@._V1_.jpg",

            },
            {
              id: 11,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMTBkNTAzNGMtNmUwNi00ZmZkLTk3MjEtZWY1NzczYTQyNTMyXkEyXkFqcGdeQXVyMTEzNzg0Mjkx._V1_.jpg",

            },
            {
              id: 12,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMGYyYjJjMzUtZTNlMi00ODliLWI2NTMtODExOTg3YWU2NTE1XkEyXkFqcGdeQXVyNDc2NzU1MTA@._V1_FMjpg_UX1000_.jpg",

            },
          ]}
          isLargeRow={true}
        />
        <MRow
          title="Popular  Movies"
          movies={[
            {
              id: 1,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BM2RiZDVjYWEtZGNhYy00ZGU0LTgwZjMtZTJmNmMyNGQ5NGYyXkEyXkFqcGdeQXVyNjY1MTg4Mzc@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 2,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZTk5ODdhMzYtNTIzZi00MTY4LTgwOWQtODgzYTdhZDRjM2IwXkEyXkFqcGdeQXVyOTY3OTAzOTQ@._V1_.jpg",

            },
            {
              id: 3,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BOGVjYmM0ZWEtNTFjNi00MWZjLTk3OTItMmFjMDAzZWU1ZDVjXkEyXkFqcGdeQXVyMTI2Mzk1ODg0._V1_.jpg",

            },
            {
              id: 4,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNGVjNWI4ZGUtNzE0MS00YTJmLWE0ZDctN2ZiYTk2YmI3NTYyXkEyXkFqcGdeQXVyMTkxNjUyNQ@@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 5,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZDkzMTQ1YTMtMWY4Ny00MzExLTkzYzEtNzZhOTczNzU2NTU1XkEyXkFqcGdeQXVyODY3NjMyMDU@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 6,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNTYxYzIyZmYtMjAxMi00MWU2LWFiYTgtM2VlMjQ1YmYyYTcwXkEyXkFqcGdeQXVyMjk5MDYzMDA@._V1_.jpg",

            },
            {
              id: 7,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNDNhNGNmZDctNDNmYS00ZTM1LWEwYmItOTU3NWQ3MGEwMGNkXkEyXkFqcGdeQXVyMTI2MTI0MjIx._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 8,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BOGZiM2IwODktNTdiMC00MGU1LWEyZTYtOTk4NTkwYmJkNmI1L2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_.jpg",

            },
            {
              id: 9,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BN2FjNmEyNWMtYzM0ZS00NjIyLTg5YzYtYThlMGVjNzE1OGViXkEyXkFqcGdeQXVyMTkxNjUyNQ@@._V1_.jpg",

            },
            {
              id: 10,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMjM3MjQ1MzkxNl5BMl5BanBnXkFtZTgwODk1ODgyMjI@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 11,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNWIwODRlZTUtY2U3ZS00Yzg1LWJhNzYtMmZiYmEyNmU1NjMzXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 12,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMTBkNTAzNGMtNmUwNi00ZmZkLTk3MjEtZWY1NzczYTQyNTMyXkEyXkFqcGdeQXVyMTEzNzg0Mjkx._V1_.jpg",

            },
          ]}
          isLargeRow={true}
        />
        <MRow
          title="Popular Showss"
          movies={[
            {
              id: 1,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMjEzNzMwNjAzMl5BMl5BanBnXkFtZTgwMTI4MzY3NjM@._V1_.jpg",

            },
            {
              id: 2,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMGU0MjQzYjQtNmQ4MS00MGQ2LTlhNDUtOTFmMWJkN2I3NGNiXkEyXkFqcGdeQXVyMTExMTIzMTA5._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 3,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMzRjZWVmMzItNTdmYS00OWEzLTgyOGUtNThiNTU2ZThlYjY0XkEyXkFqcGdeQXVyMTIxMDk2NDE4._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 4,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZDkxODJmNDktZDcxMy00MGJkLWI3NzEtNzVkYTdkZWI0ZmVkXkEyXkFqcGdeQXVyNDY4NjAxNTc@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 5,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYTE3NTA5YzUtZDljNy00ZGNjLThiYmMtYmFmY2M1Y2EzMzk5XkEyXkFqcGdeQXVyODQ5NDUwMDk@._V1_.jpg",

            },
            {
              id: 6,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYjE2YjY4YjgtNjkyYS00ZDQ0LWE5ZDUtZGU3NDU3NzRlZWRlXkEyXkFqcGdeQXVyMTIxMDk2NDE4._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 7,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNmVmMmM5ZmItZDg0OC00NTFiLWIxNzctZjNmYTY5OTU3ZWU3XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_.jpg",

            },
            {
              id: 8,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMDNkOTE4NDQtMTNmYi00MWE0LWE4ZTktYTc0NzhhNWIzNzJiXkEyXkFqcGdeQXVyMzQ2MDI5NjU@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 9,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMDQ4MDM0YjEtMTcwMC00YTFiLTlkZmYtYzhmODhjNDIwNzA3XkEyXkFqcGdeQXVyMTI1NDAzMzM0._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 10,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BOTYzMDZiM2UtYjgyOS00Zjg5LTkyYWEtOTc4NTg4M2QyZjdiXkEyXkFqcGdeQXVyNTE3OTc0MDQ@._V1_.jpg",

            },
            {
              id: 11,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNTNkMTg4YmEtNWViZS00NGMwLWIwYmItMGVmYjU1ZTY1ZGYyXkEyXkFqcGdeQXVyMzA5MTg1Mzc@._V1_.jpg",

            },
            {
              id: 12,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMTg0NjEwNjUxM15BMl5BanBnXkFtZTcwMzk0MjQ5Mg@@._V1_FMjpg_UX1000_.jpg",

            },
          ]}
          isLargeRow={true}
        />
      </div>
      <div
        classsName="col-md-11"
        style={{
          marginTop: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <ProgressB
            ott="Prime Video"
            beginnerPrice="5"
            cinephilePrice="8"
            bingePrice="12"
        />        
      </div>
      <section id="buy">
      <a
        href="/#faq"
        className="any_queries"
        style={{
            textDecoration: "none",
            color: "white",
            marginTop: "5px",
            marginBottom: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            fontSize: "10px",
          }}
          
      >Any queries?</a>
      </section>
      <Footer/>
    </div>
  );
}

export default Prime;
