import React from 'react';
import './Pricing.css';
import netflix from '../assets/img/netflix.webp';
import prime from '../assets/img/prime.webp';
import disney from '../assets/img/disney.webp';
import aha  from '../assets/img/aha.webp';
import zee5 from '../assets/img/zee5.webp';
import sonyliv from '../assets/img/sonyliv.webp';
import voot from '../assets/img/voot.webp';

function PricingMobile() {
  return (
    <>
        <section id='pricing' className=''>
        <div style={{margin:"30px"}}>
            <h1 style={{ textAlign: "center",padding:"10px" }}>
                Resume the laze <span style={{ color: "#fed530" }}>o' clock</span> with the cine plan
            </h1>
            <div className='row justify-content-center'>
                {/* <div className='col-md-4 col-sm-6'>
                    <div className=" pricing-card d-flex justify-content-around">
                            <div className="">
                                <br></br>
                                <p>At just</p>
                            <h1>
                                <span
                                style={{fontSize:"3rem"}}
                                >₹9</span>
                            </h1>
                            </div>
                            <div className="col-5">
                                <img
                                    src={netflix}
                                    alt="netflix" 
                                    style={{margin:"5px 0px", width:"12vmax"}}
                                />
                                <h6 className='bg_bp'>
                                    Beginner Plan <br/> (4 HRS)
                                </h6>
                                <button className="viewMore btn"
                                    onClick={() => {
                                        window.location.href = "/netflix-show";
                                    }}
                                > View More</button>
                            </div>
    
                    </div>

                 </div> */}

                 <div className='col-md-4 col-sm-6 col-lg-4'>
                    <div className=" pricing-card d-flex justify-content-around">
                            <div className="">
                                <br></br>
                                <p>At just</p>
                            <h1>
                                <span
                                style={{fontSize:"3rem"}}
                                >₹5</span>
                            </h1>
                            </div>
                            <div className="col-5">
                                <img
                                    src={prime}
                                    alt="prime video"
                                    style={{margin:"10px 0px", width:"12vmax"}}
                                />
                                <h6 className='bg_bp m-auto'>
                                    Beginner Plan <br/> (4 HRS)
                                </h6>
                                <button className="viewMore btn"
                                    onClick={() => {
                                        window.location.href = "/prime-show";
                                    }}
                                > View More</button>
                            </div>
    
                    </div>

                 </div>

                 <div className='col-md-4 col-sm-6'>
                    <div className=" pricing-card d-flex justify-content-around">
                            <div className="">
                                <br></br>
                                <p>At just</p>
                            <h1>
                                <span
                                style={{fontSize:"3rem"}}
                                >₹3</span>
                            </h1>
                            </div>
                            <div className="col-5">
                                <img
                                    src={voot}
                                    alt="voot"
                                    style={{margin:"10px 0px", height:"5vmax",width:"10vmax"}}
                                />
                                <h6 className='bg_bp m-auto'>
                                    Beginner Plan <br/> (4 HRS)
                                </h6>
                                <button className="viewMore btn"
                                    onClick={() => {
                                        window.location.href = "/voot-show";
                                    }}
                                > View More</button>
                            </div>
    
                    </div>

                 </div>
                 <div className='col-md-4 col-sm-6'>
                    <div className=" pricing-card d-flex justify-content-around">
                            <div className="">
                                <br></br>
                                <p>At just</p>
                            <h1>
                                <span
                                style={{fontSize:"3rem"}}
                                >₹5</span>
                            </h1>
                            </div>
                            <div className="col-5">
                                <img
                                    src={disney}
                                    alt="disney plus hotstar"
                                    style={{margin:"10px 0px", width:"10vmax"}}
                                />
                                <h6 className='bg_bp m-auto'>
                                    Beginner Plan <br/> (4 HRS)
                                </h6>
                                <button className="viewMore btn"
                                    onClick={() => {
                                        window.location.href = "/hotstar-show";
                                    }}
                                > View More</button>
                            </div>
    
                    </div>

                 </div>

                 <div className='col-md-4 col-sm-6'>
                    <div className=" pricing-card d-flex justify-content-around">
                            <div className="">
                                <br></br>
                                <p>At just</p>
                            <h1>
                                <span
                                style={{fontSize:"3rem"}}
                                >₹4</span>
                            </h1>
                            </div>
                            <div className="col-5">
                                <img
                                    src={sonyliv}
                                    alt="sonyliv"
                                    style={{margin:"10px 0px", width:"13vmax"}}
                                />
                                <h6 className='bg_bp m-auto'>
                                    Beginner Plan <br/> (4 HRS)
                                </h6>
                                <button className="viewMore btn"
                                    onClick={() => {
                                        window.location.href = "/sonyliv-show";
                                    }}
                                > View More</button>
                            </div>
    
                    </div>

                 </div>

                 <div className='col-md-4 col-sm-6'>
                    <div className=" pricing-card d-flex justify-content-around">
                            <div className="">
                                <br></br>
                                <p>At just</p>
                            <h1>
                                <span
                                style={{fontSize:"3rem"}}
                                >₹4</span>
                            </h1>
                            </div>
                            <div className="col-5">
                                <img
                                    src={aha}
                                    alt="aha"
                                    style={{margin:"20px 0px", width:"10vmax"}}
                                />
                                <h6 className='bg_bp m-auto'>
                                    Beginner Plan <br/> (4 HRS)
                                </h6>
                                <button className="viewMore btn"
                                    onClick={() => {
                                        window.location.href = "/aha-show";
                                    }}
                                > View More</button>
                            </div>
    
                    </div>

                 </div>

                 {/* <div className='col-md-4 col-sm-6'>
                    <div className=" pricing-card d-flex justify-content-around">
                            <div className="">
                                <br></br>
                                <p>At just</p>
                            <h1>
                                <span
                                style={{fontSize:"3rem"}}
                                >₹4</span>
                            </h1>
                            </div>
                            <div className="col-5">
                                <img
                                    src={zee5}
                                    alt="zee5"
                                    style={{margin:"10px 0px", width:"10vmax"}}
                                />
                                <h6 className='bg_bp'>
                                    Beginner Plan <br/> (4 HRS)
                                </h6>
                                <button className="viewMore btn"
                                    onClick={() => {
                                        window.location.href = "/zee5-show";
                                    }}
                                > View More</button>
                            </div>
    
                    </div>

                 </div> */}

                 

            </div>
            
        </div>
        </section>
    </>
  )
}

export default PricingMobile
