import React, { Component } from "react";
import { NavBar } from "./NavBar";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import MRow from "./MRow";
import "./PageBanner.css";
import PageBannerComp from "./PageBannerComp";
import { ProgressB } from "./ProgressB";
import Footer from "./Footer";
import netflix from "../assets/img/netflix.webp";
import wed from "../assets/img/Banner/wednesday.webp"
import swed from "../assets/img/Banner/swed.webp"
import mm from "../assets/img/Banner/mm.webp";
import smm from "../assets/img/Banner/smm.webp";
import st from "../assets/img/Banner/st.webp";
import sst from "../assets/img/Banner/sst.webp";
import lt from "../assets/img/Banner/lovetoday.webp";
import sld from "../assets/img/Banner/sld.webp";
function Netflix() {
  // generate random number between 1 and 4
  const seq = Math.floor(Math.random() * 4) + 1;

  return (
    <div>
      <NavBar />
      <section className="pbanner" id="home">
        {seq === 1 && (
          <PageBannerComp
           // if window size is less than 991px then image will be large else small
           image={
            window.innerWidth > 600 ? wed : swed
          }
            title="Wednesday"
            description="Follows Wednesday Addams' years as a student, when she attempts to master her emerging psychic ability, 
            thwart a killing spree, and solve the mystery that embroiled her parents."
            BannerCompLink="#buy"
            logoLink={netflix}
          />
        )}
        {seq === 2 && (
          <PageBannerComp
          image={
            window.innerWidth > 600 ? mm : smm
          }
            title="Mision Majnu"
            description="In the 1970s, an undercover Indian spy takes on a deadly mission to expose a covert nuclear weapons program in the heart of Pakistan."
            BannerCompLink="#buy"
            logoLink={netflix}
          />
        )}
        {seq === 3 && (
          <PageBannerComp
          image={
            window.innerWidth > 600 ? st : sst
          }
            title="Stranger Things"
            description="When a young boy disappears, his mother, a police chief and his friends must confront terrifying supernatural forces in order to get him back."
            BannerCompLink="#buy"
            logoLink={netflix}
          />
        )}
        {seq === 4 && (
          <PageBannerComp
          image={
            window.innerWidth > 700 ? lt : sld
          }
            title="Love Today"
            description="Two young lovers, who are made to exchange their phones for a day and what happens due to that."
            BannerCompLink="#buy"
            logoLink={netflix}
          />
        )}
      </section>
      <div
        className=""
        style={{
          marginTop: "20px",
          marginLeft: "20px",
        }}
      >
        <MRow
          title="New Releases"
          movies={[
            {
              id: 1,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMGU3ODQxMGEtZjlhNy00ZTMxLThkNDktNmY0YjA3ZTY1NjJkXkEyXkFqcGdeQXVyMTQ3MjQ2NzA0._V1_.jpg",
            },
            {
              id: 2,
              imglink:
                "https://gkindia.org/ib/VrmHPPpHl6.jpg",
            },
            {
              id: 3,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYjcxY2QwZmMtNjE1MS00OGU2LWJiMjYtOTc3ZTk5NmI2ODc1XkEyXkFqcGdeQXVyMTAyMjQ3NzQ1._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 4,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNGE3MDlkZTItMWVlMy00MThiLWFmN2MtYTliYzFhNjgyYjk2XkEyXkFqcGdeQXVyNjI4NDY5ODM@._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 5,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNmQxYmNhNzctOWY1NC00NTE4LTg2ZjktZTA2YTJkZDJmZWI4XkEyXkFqcGdeQXVyMTEzMTI1Mjk3._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 6,
              name: "Avengers",
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMjExNzRlMDUtZjBhNi00OTkwLTg5YzQtNTgyNjFiYjkxYjYyXkEyXkFqcGdeQXVyNjkwOTg4MTA@._V1_.jpg",
              
            },
            {
              id: 7,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BM2ZmMjEyZmYtOGM4YS00YTNhLWE3ZDMtNzQxM2RhNjBlODIyXkEyXkFqcGdeQXVyMTUzMTg2ODkz._V1_.jpg",
              
            },

            {
              id: 8,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYTYwYmI0NGItYmFkYi00NzViLWIwMGEtNGNjYjQwYjY1NTQ1XkEyXkFqcGdeQXVyODE5NzE3OTE@._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 9,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BOTg0OGFmNGEtZjhkZi00ZTViLWIyYjMtZjM2MGIyZTRhYjQxXkEyXkFqcGdeQXVyMTEzNzg0Mjkx._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 10,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYzFmMWNmOGQtNmE2NS00ZjdkLTlhZGEtY2ZmZjI5N2UwZjkzXkEyXkFqcGdeQXVyMTU0ODI1NTA2._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 11,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMGVjZmFkZjItNDFkOS00NDIzLWEzN2YtZDAyZGIxZWI1MDMzXkEyXkFqcGdeQXVyMTQyOTA1NTMz._V1_.jpg",
              
            },
            {
              id: 12,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMTBlMDI1MWUtYTAyNC00NTczLWE3MWYtYzE3YWNmYWQwZmM3XkEyXkFqcGdeQXVyOTI3MzI4MzA@._V1_FMjpg_UX1000_.jpg",
              
            }
          ]}
          isLargeRow={true}
        />
        <MRow
          title="Popular Movies"
          movies={[
            {
              id: 1,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNjEzNDViNmEtYTczYi00NjkyLTgyZGItY2M1YWMwZGJlMjE3XkEyXkFqcGdeQXVyMTUzNTgzNzM0._V1_.jpg",
              
            },
            {
              id: 2,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYTYwYmI0NGItYmFkYi00NzViLWIwMGEtNGNjYjQwYjY1NTQ1XkEyXkFqcGdeQXVyODE5NzE3OTE@._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 3,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BOGQ3ZjMwZDctZTFkMi00ZjUwLTlmMDUtNDZlYzJjNzZiNTg5XkEyXkFqcGdeQXVyMTMzODk3NDU0._V1_.jpg",
              
            },
            {
              id: 4,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMDJiOTdmMGItMmM5MC00ZTRiLWIzNjctNDE4ZTZkMWMzZTg0XkEyXkFqcGdeQXVyOTI3MzI4MzA@._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 5,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BY2Q1ZjBjMTMtNjQxOS00OTRkLThmZjUtZWUyMzVhMTA4MGE1XkEyXkFqcGdeQXVyNTgxODY5ODI@._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 6,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BOGRkMDgwMjMtZmFkZi00NjEwLTllMDktMDcxZmQxYmYwNTVlXkEyXkFqcGdeQXVyODk4OTc3MTY@._V1_.jpg",
              
            },
            {
              id: 7,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZWQzYWI3ZGMtYzgyYy00OWZkLWEwODYtNGNiMTZhODBkNzUyL2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyNjY1MTg4Mzc@._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 8,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYmIzYmY4MGItM2I4YS00OWZhLWFmMzQtYzI2MWY1MmM3NGU1XkEyXkFqcGdeQXVyNjQ2MjQ5NzM@._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 9,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BN2M4NDM2NDItMzgzNy00OWRiLThhNjEtZDA2OWMyNTcwYzRjXkEyXkFqcGdeQXVyMTI1NDEyNTM5._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 10,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BODA4MjM2ODk4OF5BMl5BanBnXkFtZTcwNDgzODk1OQ@@._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 11,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYzA4NTI1MWYtOTRhZi00MDYyLThhNjQtZmUyZDdjZTNlNWJiXkEyXkFqcGdeQXVyMTI1NDEyNTM5._V1_.jpg",
              
            },
            {
              id: 12,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZTQwODI0YTYtZmIxYy00YTk2LWFlMDgtNmI1M2ZiNjRiZDBmXkEyXkFqcGdeQXVyNTgxODY5ODI@._V1_.jpg",
              
            },
          ]}
          isLargeRow={true}
        />
        <MRow
          title="Popular Shows"
          movies={[
            {
              id: 1,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BM2ZmMjEyZmYtOGM4YS00YTNhLWE3ZDMtNzQxM2RhNjBlODIyXkEyXkFqcGdeQXVyMTUzMTg2ODkz._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 2,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNjE3ZjIxZTUtYzE0ZC00M2ZiLWE0MjAtZDViZGMyNjE5NjVkXkEyXkFqcGdeQXVyMjU5NDU0Mjc@._V1_.jpg",
              
            },
            {
              id: 3,
              imglink:
              "https://coffeekdramaandotherobsessions.files.wordpress.com/2020/12/true.jpg",
              
            },
            {
              id: 4,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BODE5OWM4ZjUtN2FjMy00ZWUyLWEyMWItM2IyOTU3MDRjMGMxXkEyXkFqcGdeQXVyMTUzMTg2ODkz._V1_.jpg",
              
            },
            {
              id: 5,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BOTUzMTMzNTgtNmI5NS00ZTI1LTgzMjYtZTFkZWZkYTE0MWMyXkEyXkFqcGdeQXVyNjY1MTg4Mzc@._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 6,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMzlmMGE2NGYtMWFiOS00NDAwLTg1MzYtMDc1Zjc0ODExYjExXkEyXkFqcGdeQXVyMTMzNzIyNDc1._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 7,
              
              imglink:
                "https://assets.gadgets360cdn.com/pricee/assets/product/202211/khakee_bihar_chapter_poster_1669631052.jpeg",
              
            },
            {
              id: 8,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNjRiNmNjMmMtN2U2Yi00ODgxLTk3OTMtMmI1MTI1NjYyZTEzXkEyXkFqcGdeQXVyNjAwNDUxODI@._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 9,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMDk3YzgxNDQtNTEzOS00NDMyLWFlYmYtYTZlMDk1NDkxNmMyXkEyXkFqcGdeQXVyNzA5NjUyNjM@._V1_.jpg",
              
            },
            {
              id: 10,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BODExY2U2ODQtYWI2Ny00NDcyLWEyMzEtYWM2ZDM0MDE1MDVmXkEyXkFqcGdeQXVyMTUzOTcyODA5._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 11,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZDdjYmZlMzQtN2JmZS00N2JkLTg4MGYtMGI3OGVhMWQ1MzMyXkEyXkFqcGdeQXVyMTEzMTI1Mjk3._V1_FMjpg_UX1000_.jpg",
              
            },
            {
              id: 12,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZTIyMGI0ZjktOWRjZS00N2Y3LTgxYTgtNTY0NmVkMmY3MzllXkEyXkFqcGdeQXVyMTEzMTI1Mjk3._V1_.jpg",
              
            },
          ]}
          isLargeRow={true}
        />
      </div>
      <div
        classsName="col-md-11"
        style={{
          marginTop: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <ProgressB
            ott="Netflix"
            beginnerPrice="9"
            cinephilePrice="16"
            bingePrice="24"
        />        
      </div>
      <section id="buy">
      <a
        href="/#faq"
        className="any_queries"
        style={{
            textDecoration: "none",
            color: "white",
            marginTop: "5px",
            marginBottom: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            fontSize: "10px",
          }}
      >Any queries?</a>
      </section>
      <Footer/>
    </div>
  );
}

export default Netflix;
