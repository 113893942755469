import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import Banner from './components/Banner';
import Aboutus from './components/Aboutus';
import Pricing from './components/Pricing';
import PricingMobile from './components/PricingMobile';
import FAQ from './components/FAQ';
import Footer from './components/Footer';


function Home() {
  return (
    <div style={{backgroundColor:'#1b1b1b'}}>
      <NavBar/>
      <Banner/>
      <Aboutus/>
      <PricingMobile/>
      {/* {
        window.innerWidth > 991 ? <Pricing/> : <PricingMobile/>
      } */}
      <FAQ/>
      <Footer/>
    </div>
  )
}

export default Home
